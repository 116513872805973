import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Icon, Menu } from 'semantic-ui-react';
import { NotificationItem } from '../menus/NotificationItem';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from 'react-calendar';
import Debug from '../../Debug';
import { INPROGRESS, COLOR_PRIMARY } from '../../assets/literals';
import 'react-calendar/dist/Calendar.css';
import { StyledContainer, SizingContainer, HorizontalDiv } from '../StyledComponents';
import { InstallPWA } from './InstallPWA';
import logo from '../../assets/logo_color_transparent.png';
import HeaderMain from './HeaderMain';
import DailyLog from '../log/DailyLog';
import LogView from '../log/LogView';
import { WelcomeModal } from './WelcomeModal';
import { DummyParagraph } from '../common/Loading';
import PerformanceReview from '../common/PerformanceReview';
import { YearInReview } from '../common/YearInReview';
import Chat from '../common/Chat_Old';
import { firebaseAuth } from '../../firebase';
import { retrieveUserConfig, retrieveCustomExercises, retrieveWorkouts, retrieveTrackWorkouts, clearSelectedWorkout, selectWorkout, startWorkoutBuilder, createUserConfig, updateUserConfig} from '../../actions';
import { getTracks, intensityResolution } from '../../commonMethods';

  const INITIAL_STATE = {
    date: new Date(Date.now()),
    edit: false
  }

class Track extends Component {

  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)));

    try {
      if (this.props.log && this.props.log.workoutBuilder && this.props.log.workoutBuilder.date)
        Debug('Not refreshing');
      else
        this.handleDateChange(new Date());
    }
    catch (error) {
      console.log(error)
      alert('An unexpected error has occurred: ' + error.message);
      //this.handleDateChange(new Date())
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('component update', this.props, prevProps, prevState)
    Debug(this.props.log);

    if (this.props.log.didInvalidate === true || (_.isEmpty(prevProps.config.team) && !_.isEmpty(this.props.config?.team))) {
      console.log('updatingg...', prevProps, this.props)
      if (this.state.date)
        this.handleDateChange(this.state.date); //re-fetch when data is invalidated
    }
  }

  isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
  }

  isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  handleDateChange = date => {
    const { config } = this.props;
    const { team } = config;
    const tracks = getTracks(firebaseAuth.currentUser.uid, team?.tracks);
    console.log('trackssss:', tracks, team, config)
    Debug(date);
    var start = new Date(date.getTime());
    start.setHours(0,0,0,0);
    var end = new Date(start.getTime());
    end.setHours(23,59,59,999);
    Debug(start, end);
    this.setState({ date});
    this.props.retrieveWorkouts(start, end);
    for (var i=0; i<tracks.length; i++) {
       this.props.retrieveTrackWorkouts(start, end, tracks[i]);
    }
  };

  tileClassName = ({ date, view }) => view ===
    'month' && date === this.state.selected ? 'red' : null;


  renderDatePicker = () => {
    return <>
        <DatePicker
          id='datePicker'
          className='ui button circular'
          dateFormat="MMM d, yyyy"
          selected={this.state.date}
          onChange={date => this.handleDateChange(date)}
        />
      </>
  }

  renderCalendar = () => {
    return <Calendar
      onChange={date => this.handleDateChange(date)}
      value={new Date(this.state.date)}
      tileClassName={this.tileClassName}
      tileContent={({ date, view }) => view === 'month' && date.getDay() === 0 ? <p>It's Sunday!</p> : null}
    />
  }

  renderSummary = (workouts) => {
    window.scrollTo(0, 0)
    const {log, config } = this.props;
    const { loading } = log;

    if (loading) {
        return  <DummyParagraph height="100" />
    }
    else if (workouts.length > 0) {
      return workouts.map((workout, index) =>
        <LogView
          showMenu
          showName
          showStartButton
          workout={workout}
          key={index}
          athleteConfig={config.user}
        />
      )
    }
    else {
      return <><br/><br/><p style={{fontSize: '20px'}}> No workouts logged for the day. </p></>
    }
  }

  containsTemplate = (workouts, templateId) => {
    for (var i=0; i<workouts.length; i++) {
      if (workouts[i].templateId === templateId)
        return true;
    }
    return false;
  }

  renderTrackWorkouts = (trackWorkouts, workouts) => {
    const { config } = this.props;
    const { team, user, customExercises } = config;
    return <div>
      {trackWorkouts.map((template, index) => {
          if (this.containsTemplate(workouts, template.id))
            return null;
          return <LogView
              title={team.name + " Scheduled Workout"}
              showName
              workout={this.templateToWorkout(template, user.workingMaxes, customExercises)}
              showStartButton
              key={index}
              athleteConfig={config.user}
            />
      })}
    </div>
  }

  templateToWorkout = (template, workingMaxes, customExercises) => {
    let workout = _.cloneDeep(template);
    workout.templateId = template.id;
    delete workout.id;
    workout.userId = firebaseAuth.currentUser.uid;
    let { exerciseArr } = workout;
    for (var i=0; i<exerciseArr.length; i++) {
      exerciseArr.workingMax = intensityResolution(exerciseArr[i].exercise, workingMaxes, customExercises);
    }
    return workout;
  }

  renderLog = () => {
    const { date } = this.state;
    const { log, location, config } = this.props;
    const { user } = config;
    Debug(log);
    const { workouts, trackWorkouts, selectedWorkout, workoutBuilder } = log;
    Debug(workoutBuilder === {});
    if (selectedWorkout !== null) {
      //const workout =  workouts.filter(x => x.id === selectedWorkout)[0];
      return <>
          <DailyLog
            key={selectedWorkout}
            handleDateChange={this.handleDateChange}
            fromCalendar={location.state ? location.state.fromCalendar : false}
            athlete={location.state && !location.state.trackMode ? location.state.athlete : null}
            programming={location.state ? location.state.programming : false}
            ignoreIntensity={location.state && location.state.trackMode}
            trackMode={location.state && location.state.trackMode}
          />
        </>
    }
    else {
      return <>
      <StyledContainer>
      {false && config.isMobile &&
        <Menu style={{marginTop: '10px', borderBottom: '2px solid lightgrey'}} compact fluid secondary>
          <NotificationItem
            num='4'
          />
        </Menu>}
      <div style={{width:'100%',backgroundColor: COLOR_PRIMARY, boxShadow: '0 12px 24px rgba(0, 0, 0, 0.3)' }}><img style={{width: '99%', maxWidth: '200px', padding:'2%', paddingBottom: '0'}} src={logo} alt='||--||' />
        <HorizontalDiv>
        <Button fluid icon size='huge'
            style={{background: 'transparent', color: 'lightgrey', fontSize: '24px'}}
            onClick={(e) => this.handleDateChange(new Date(new Date(this.state.date).setDate(this.state.date.getDate()-1)))}
         >
           <Icon name='caret left'/>
         </Button>
         {this.renderDatePicker()}
        <Button fluid compact icon size='huge'
              style={{background: 'transparent', color: 'lightgrey', fontSize: '24px'}}
              onClick={(e) => this.handleDateChange(new Date(new Date(this.state.date).setDate(this.state.date.getDate()+1)))}
           >
            <Icon name='caret right'/>
          </Button>
        </HorizontalDiv>
        </div>
        <br/>
        <div style={{width: '100%'}}>
        {trackWorkouts && this.renderTrackWorkouts(trackWorkouts, workouts)}
        {this.renderSummary(workouts)}
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <div>
            <br/>
            <Button
                fluid secondary
                size='large'
                onClick={(e) => {this.props.startWorkoutBuilder(this.state.date, this.state.date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' }) + ' Workout', firebaseAuth.currentUser.uid, "", INPROGRESS, location?.state?.trackMode)}}
              >
                Log New Session
              </Button>
            </div>
          </div>
          <br/>
          <HorizontalDiv>
            <Button basic fluid  color='black'
              onClick={() => { this.props.history.push('programs')}}
            >
              Programs
            </Button>
          </HorizontalDiv>
          <br/><br/>
          {user && user.defaultUnit && <PerformanceReview
            athlete={firebaseAuth.currentUser.uid}
            config={config}
            date={date}
          />}
          <br/><br/><br/><br/>
          <a style={{color : 'grey' }} href='https://instagram.com/traintensity' target="_blank" rel="noreferrer">
            <div> Follow us on Instagram <Icon size='large' name='instagram' />
            <br/>
            Tag us in your training videos!
            <br/>
            </div></a>
          <br/>
        </div>
        </StyledContainer></>
    }
  }

  render() {
    Debug(this.props);
    const { config } = this.props;
    const {user} = config;

    return(
      <>
      <HeaderMain
        activeItem='Track'
        />
      <StyledContainer>
        <SizingContainer>
          {(!user || (user && user.isOnboarded === false)) && (!config.isMobile || this.isInStandaloneMode()) &&
            <WelcomeModal onExit={() => {
                  if (user === undefined)
                    this.props.createUserConfig();
                  else if (user && user.isOnboarded === false)
                    this.props.updateUserConfig('isOnboarded', true)
             }}/>
          }
          {this.renderLog()}
          <br/>
        </SizingContainer>
        <br/><br/>
        <InstallPWA/>
      </StyledContainer>
      </>
    );
  }
}

const mapStateToProps = state => {
  Debug(state);
  return {
           log: state.log,
           config : state.config
         }
  };

export default connect(mapStateToProps, {retrieveUserConfig, retrieveCustomExercises, createUserConfig, updateUserConfig, retrieveWorkouts, retrieveTrackWorkouts, selectWorkout, clearSelectedWorkout, startWorkoutBuilder})(Track);
