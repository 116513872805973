import React, { useContext } from 'react';
import { connect } from 'react-redux';
import './App.css';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Debug from './Debug';
import { AuthContext } from './components/navigation/Auth'
import Home from './components/navigation/Home';
import Track from './components/navigation/Track';
import SignIn from './components/navigation/SignIn';
import SignUp from './components/navigation/SignUpNew';
import Progress from './components/navigation/Progress';
import LoggedOutRoute from "./components/navigation/LoggedOutRoute";
import PrivateRoute from "./components/navigation/PrivateRoute";
import TrainingCalendar from "./components/navigation/TrainingCalendar";
import Records from "./components/navigation/Records";
import ExercisesView from "./components/navigation/ExercisesView";
import Profile from "./components/navigation/Profile";
import Programs from "./components/navigation/Programs";
import Team from "./components/navigation/Team";
import { retrieveUserConfig, retrieveCustomExercises, retrieveSubscriptions } from './actions';

const App = ({retrieveUserConfig, retrieveCustomExercises, retrieveSubscriptions}) => {

  const {currentUser} = useContext(AuthContext);

  if (currentUser && currentUser.uid) {
      Debug(currentUser)
      retrieveUserConfig();
      retrieveCustomExercises();
      retrieveSubscriptions();
  }

    return (
        <Router>
          <div className="App">
           <Switch>
              <PrivateRoute exact path="/" component={Track} />
              <PrivateRoute exact path = "/h4m3" component={Home} />
              <LoggedOutRoute exact path="/login" component={SignIn} />
              <LoggedOutRoute exact path="/signup" component={SignUp} />
              <PrivateRoute exact path="/progress" component={Progress} />
              <PrivateRoute exact path="/calendar" component={TrainingCalendar} />
              <PrivateRoute exact path ='/records' component={Records} />
              <PrivateRoute exact path ='/exercises' component={ExercisesView} />
              <PrivateRoute exact path ='/profile' component={Profile} />
              <PrivateRoute exact path ='/programs' component={Programs} />
              <PrivateRoute exact path ='/team' component={Team} />
              <Redirect to='/' />
            </Switch>
          </div>
        </Router>
    )
}

export default connect(null, {retrieveUserConfig, retrieveCustomExercises, retrieveSubscriptions })(App);
